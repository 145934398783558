// export const Content = [
//   {
//     category: "about",
//     link: `${window.location.origin}/about#me`,
//     name: "Me, Myself and I",
//     excerpt: `I am a software engineer and enjoy building things in digital space. 
//     I love to solve problems and looking to add and create value for people through software.`
//   },
//   {
//     category: "about",
//     link: `${window.location.origin}/about#skills`,
//     name: "An overview of my technical skills and interests",
//     excerpt: `HTML, CSS, JavaScript, React, GraphQL, Gatsby, Python, Java, Linux, Blockchain Applications.`
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/TutCode",
//     name: "TutCode - site to simultaneously  view tutorials and code",
//     excerpt: `A website where people can simultaneously code and watch/read
//     tutorials. It's a more convenient way to learn and practice
//     how to code (with HTML, CSS and JavaScript) on the same page`
//   },
//   {
//     category: "works",
//     link: `${window.location.origin}`,
//     name: "Gfolio (this website)",
//     excerpt: `A personal website simulating the Google Search platform. Developed with
//     HTML, CSSS and React`
//   },
//   {
//     category: "works",
//     link: "https://enjeck.com/",
//     name: "Personal Website",
//     excerpt: `My personal website, designed and developed from scratch. It
//     features a lot of hover effects, custom styling and a blog.`
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/libre-logos",
//     name: "Libre Logos",
//     excerpt: `A project to provide free logos to open source projects.
//     Website and logos designed and built by yours truly. 
//     The website is responsive and the individual logo pages are 
//     programmatically-generated.`
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/jpg2svg",
//     name: "jpg2svg",
//     excerpt: `An program to convert JPG/JPEG images into SVG. It comes with a web interface where 
//     you can upload an image, have it converted to SVG in the server, and you can 
//     download the converted SVG file if you want.`
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/CrazyPassword",
//     name: "Crazy Password",
//     excerpt: `A password validation game with ridiculous requirements. 
//     Using Django for this project was overkill, but I really wanted to 
//      play around with regular expressions in Python, and Django already had
//      a password validation engine I could add unto.`
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/Geo-Guess",
//     name: "Geo Guess",
//     excerpt: `A distance guessing game. You are shown random cities around the world,
//     and you have to guess how far you are from the random city. The better your
//     guesss, the higher your score.`
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/btns",
//     name: "btns",
//     excerpt: `A collection of buttons with cool hover effects.`,
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/Blobby",
//     name: "Blobby",
//     excerpt: `
//     Generative blob SVG characters using Python. No characters are the same! 
//     Each Blobby character has a different body shape. The shape is always unique, 
//     and the colors and eyes are randomly applied to each shape.  `
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/Google-Sheet-to-website",
//     name: "Sheet to Site",
//     excerpt: `An experiment with building a website directly from Google Sheets. You edit a spreadsheet,
//     enter a link to the spreadsheet, and your changes are reflected on the website. `
//   },
//   {
//     category: "works",
//     link: "https://github.com/enjeck/CryptoAlgoVisualizer",
//     name: "Crypto Algorithm Visualizer",
//     excerpt: `Visualizations of various cryptography algorithms. 
//     Currently has Caesar's Cipher and Mono Alphabetic Cipher.`,
//   },

//   {
//     category: "writing",
//     link: "https://enjeck.com/blog",
//     name: "Advantages and disadvantages of SVG",
//     excerpt: `Created in 1999, SVG has grown to become the most popular vector 
//     image format for the web. This article will cover the advantages and disadvantages 
//     of SVG (Scalable Vector Graphics) as a format for displaying images on the web. `,
//   },
//   {
//     category: "writing",
//     link: "https://enjeck.com/blog",
//     name: "How to calculate the distance between two locations using JavaScript",
//     excerpt: `The most popular way of calculating the distance between two points on a 
//     sphere is using the Haversine equation. 
//     If you have the coordinates (that is; longitude and latitude) of the the starting and 
//     destination locations, you can use this equation to calculate it. `,
//   },
//   {
//     category: "writing",
//     link: "https://enjeck.com/blog",
//     name: "How to calculate the distance between two locations using JavaScript",
//     excerpt: `Knowing a user's location can be a very important feature of a web app. 
//     You can use the location information to personalise the user experience,
//     give users directions, suggest friends or events in a person's locality, or to
//     power a particular feature. `,
//   },

//   {
//     category: "social",
//     link: "https://www.linkedin.com/in/joseroquecse",
//     name: "LinkedIn",
//     excerpt: `Connect and share experiences`
//   },
//   {
//     category: "social",
//     link: "https://github.com/joseroque",
//     name: "GitHub",
//     excerpt: `View my projects and code.`
//   }
// ];

export const Content = [
  {
    category: "about",
    link: `${window.location.origin}/about#me`,
    name: "Me, Myself and I",
    excerpt: `I am a software engineer and enjoy building things in digital space. 
    I love to solve problems and looking to add and create value for people through software.`
  },
  {
    category: "about",
    link: `${window.location.origin}/about#skills`,
    name: "An overview of my technical skills and interests",
    excerpt: `HTML, CSS, JavaScript, React, GraphQL, Gatsby, Python, Java, Linux, Blockchain Applications.`
  },
  {
    category: "social",
    link: "https://www.linkedin.com/in/joseroquecse",
    name: "LinkedIn",
    excerpt: `You network is your networth. Lets connect!`
  },
  {
    category: "social",
    link: "https://github.com/joseroque",
    name: "GitHub",
    excerpt: `Code is like poetry. Some of my works can be found here.`
  }
];

