import tutcode from "../images/tutcode.png";
import enjeck from "../images/enjeck.png";
import ll from "../images/libre-logos.svg";
import boc from "../images/boc.png";
import portfolio from "../images/portfolio.png";
import github from "../images/GitHub_Logo.png";
import jpg2svg from "../images/jpg2svg.png";
import gg from "../images/geo-guess.png";
import blobby from "../images/blobby.png";
import cav from "../images/CryptographyVisualizer.png"
import placeholder from "../images/bg1.png";
export const ProjectsContent = [
  {
    link: "https://github.com/JoseRoque",
    img: github,
    name: "Github",
    excerpt: `Where my code resides`,
    tools: ""
  }, {
    link: "https://www.builtoncrypto.com",
    img: boc,
    name: "A dedicated space for latest developments in crypto space",
    tools: "React · CSS, blockchain technologies"
  }, {
    link: `${window.location.origin}`,
    img: portfolio,
    name: "Personal Website (this website)",
    excerpt: `Glimpse into my world.`,
    tools: "React · CSS"
  }
];
